export default function ({ $config }, inject) {
  let mapLoaded = false
  let mapWaiting = null
  const initMap = () => {
    mapLoaded = true
    if (mapWaiting) {
      renderMap(mapWaiting)
      mapWaiting = null
    }
  }

  const initScript = () => {
    return new Promise((resolve, reject) => {
      if (mapLoaded) return resolve(window.ymaps)
      const script = document.createElement('script')
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=${$config.YA_MAP_KEY}&lang=ru_RU&onload=initMap`
      script.async = true
      window.initMap = () => {
        resolve(window.ymaps)
      }
      script.onerror = () => {
        reject(new Error('Ошибка загрузки Yandex Maps API'))
      }
      document.head.appendChild(script)
      mapLoaded = true
    })
  }

  const addScript = () => {}

  const renderMap = ({ canvas, location }) => {
    // eslint-disable-next-line no-undef
    return new ymaps.Map(canvas, {
      center: [location.lat, location.lng],
      zoom: 18,
    })
  }

  const showMap = (params) => {
    if (mapLoaded) renderMap(params)
    else mapWaiting = params
  }

  const geocodeAddress = (address) => {
    return new Promise((resolve, reject) => {
      if (!window.ymaps) {
        initScript()
          .then(() => {
            geocodeAddress(address).then(resolve).catch(reject)
          })
          .catch(reject)
        return
      }
      window.ymaps
        .geocode(address)
        .then((res) => {
          const coordinates = res.geoObjects.get(0).geometry.getCoordinates()
          resolve(coordinates)
        })
        .catch(reject)
    })
  }

  const autocompleteAddress = async (query) => {
    try {
      await initScript()

      if (!window.ymaps) {
        throw new Error('Yandex Maps API not loaded')
      }

      const cityBounds = [
        {
          name: 'Томск',
          center: [56.486, 84.9498],
          radius: 5000, // Радиус в метрах
        },
        {
          name: 'Северск',
          center: [56.6004, 84.9756],
          radius: 5000,
        },
        {
          name: 'Новосибирск',
          center: [55.0084, 82.934],
          radius: 5000,
        },
      ]

      const suggestions = []

      for (const city of cityBounds) {
        const response = await window.ymaps.geocode(query, {
          results: 10,
          boundedBy: [
            [city.center[0] - 0.045, city.center[1] - 0.045],
            [city.center[0] + 0.045, city.center[1] + 0.045],
          ],
        })

        const geoObjects = response.geoObjects.toArray()

        geoObjects.forEach((geoObject) => {
          suggestions.push({
            address: geoObject.getAddressLine(),
            coordinates: geoObject.geometry.getCoordinates(),
            city: city.name,
          })
        })
      }

      if (suggestions.length === 0) {
        throw new Error('No suggestions found in the specified cities')
      }

      return suggestions
    } catch (error) {
      console.error('Error in autocompleteAddress:', error)
      throw error
    }
  }

  inject('maps', {
    renderMap,
    initScript,
    geocodeAddress,
    autocompleteAddress,
  })
}
